// import d3 from './modules/d3v4+jetpack.js';

import * as d3 from "d3";
// import textBalancer from "text-balancer";
import onScreen from "./modules/onScreen";

window.d3 = d3

d3.select(window).on('resize',size)

let svg = d3.select('#logo svg')

size()
function size() {
  svg
    .attr('width',(window.innerWidth < 767)?.7*window.innerWidth:.6*window.innerWidth)
    .attr('height', (window.innerWidth < 767)?.7*window.innerHeight: Math.min(.45*window.innerHeight,.65*window.innerWidth) )
}

let trails = svg.selectAll('.trail')

svg.selectAll('g.copy')
  .style('opacity',0)
  .transition()
  .duration(100)
  .delay((d,i)=>(i+1)*400)
  .style('opacity',1)
  .on('end', () => {
    animateTrails()
  })

function animateTrails() {
  trails
    .each(function(){
      let el = d3.select(this)
      let path = el.selectAll('path')

      let len = el.node().getTotalLength()
      el
        .style('stroke-dasharray', [len,len].join(' '))
        .style('stroke-dashoffset', len)
    })
    .transition()
    .duration(400)
    .delay((d,i)=>(i+1)*30+300)
    .style('opacity',1)
    .on('end', function(d,i) {
      let el = d3.select(this)
      let path = el.selectAll('path')

      let len = el.node().getTotalLength()
      el
        .transition()
        .duration(600)
        .delay((d,i)=>i*400)
        .style('stroke-dashoffset', 0)
        .on('end',() => setTimeout(d3.select('#intro').style('opacity',1), 600))

      let pEl = d3.select(this.parentNode)
      if(pEl.selectAll('circle').size()>0){
        pEl.selectAll('circle')
          .transition()
          .duration(400)
          .delay((d,i)=>i*400)
          .style('opacity',1)
      }

      if(trails.size() == i+1) {

      }
    })
}

let lst = 0; // scroll top
d3.select(window).on('scroll', function() {
  let st = window.pageYOffset || document.documentElement.scrollTop; // scroll top
  let direction =  (st > lst)? 'down' : 'up'
  lst = st;
  let pct = (window.innerHeight-scrollY)/window.innerHeight
  let leftScale = d3.scaleLinear().domain([.3,0]).range([0,-50]).clamp(true)
  let opScale = d3.scaleLog().domain([0,1]).range([0,.5]).clamp(true)

  // let curHeight = Math.max(80,window.innerHeight-scrollY)
  // d3.select('.head').style('height',curHeight+'px')
  // let trailGs = d3.selectAll('.trail-g').classed('hide-trails',curHeight<250)
  // svg.classed('nav-logo',curHeight<250)
  // svg.style('transform','translateX(calc('+leftScale(pct)+'%))')
  // if(curHeight<250){
  // }

  // svg.style('opacity',pct)
  d3.select('.head').classed('show',(1-pct)>.7)
})

d3.selectAll('.nav-item').on('click',function(e){
  let scrollSel =  d3.select(this).attr('data-scroll')

  let scrollEl = d3.select(scrollSel)
  let top = scrollEl.node().getBoundingClientRect().top
  scrollToElement(scrollEl.node())
})


function scrollToElement(element, offset = 60, duration = 400, delay = 0, easing = 'cubic-in-out', endCallback = () => {}) {
  var offsetTop = window.pageYOffset || document.documentElement.scrollTop
  d3.transition()
    .on("end", endCallback)
    .delay(delay)
    .duration(duration)
    // .ease(easing)
    .tween("scroll", (val => () => {
      var i = d3.interpolateNumber(offsetTop, val);
      return t => scrollTo(0, i(t))
    })(offsetTop + element.getBoundingClientRect().top - offset));
}


var navOnScreen = onScreen({
  selection: Array.prototype.slice.call(document.querySelectorAll('.trigger')),
  range: {top: .2, height: .4},
  cb: function(visible, direction, i) {
    var el = d3.select(this)
    if (visible && direction != "load") {
      var navItems = d3.selectAll('.nav-item')
      var navSel = '.nav-item["data-scroll=#'+el.attr('id')+'"]';
      var navEl = d3.select('.nav-item[data-scroll="#'+el.attr('id')+'"]')
      navItems.classed('active',function(){
        var curEl = d3.select(this)
        return curEl.attr('data-scroll') == '#'+el.attr('id')
      })
    }

  }
});

// textBalancer.balanceText();
