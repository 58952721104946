/* --------------------------------------------
 *
 * onScreen
 * Pass in an object with a selection and a callback
 * Will trigger callback when element becomes on or off screen
 *
 */

'use strict'

function onScreen (_conf) {
  if (!_conf) return

  var oS = {}

  oS.conf = {
      // parent: document.documentElement, // for mutation observer
      selection:[],
      cb:function(){},
      range: {top: 0, height: 1} // expressed as a portion of window height where (top + height) <= 1
    }

  oS.env = {
      _els:[],
      prevPageYOffset: null
    }

  Object.keys(_conf).forEach( key => oS.conf[key] = _conf[key] )

  function setSizes () {
    oS.env._els = []
    oS.conf.selection.forEach( function (el,i) {
      var bcr = el.getBoundingClientRect()
      oS.env._els.push({
          'el': el,
          'top': (bcr.top + pageYOffset),
          'height': bcr.height
        })
    })

    oS.env.prevPageYOffset = window.pageYOffset
  }


  function onScrollEvent (e) {
    var wH = window.innerHeight

    // Direction logic
    var direction = 'load'
    if (oS.env.prevPageYOffset < window.pageYOffset) {
      direction = 'down'
    } else if (oS.env.prevPageYOffset > window.pageYOffset) {
      direction = 'up'
    }

    oS.env._els.forEach( function (_el, i) {

      var el_bottom = _el.top + _el.height
      var oS_attr = _el.el.getAttribute('data-onScreen')


      // screen test logic
      if (
            pageYOffset + (wH * (oS.conf.range.top + oS.conf.range.height)) > _el.top
            &&
            pageYOffset + (wH * oS.conf.range.top) < el_bottom
      ) {

        // on screen
        if (oS_attr === 'on-screen') return
        _el.el.setAttribute('data-onScreen', 'on-screen')
        oS.conf.cb.call(_el.el, true, direction, i)

      } else {

        // off screen
        if (oS_attr === 'off-screen') return
        _el.el.setAttribute('data-onScreen', 'off-screen')
        oS.conf.cb.call(_el.el, false, direction, i)

      }

    })

    oS.env.prevPageYOffset = window.pageYOffset
  }


  function activateOnScreen () {

    setSizes()
    window.addEventListener('scroll', onScrollEvent)
    window.addEventListener('touchmove', onScrollEvent)
    window.addEventListener('resize', () => {setSizes(); onScrollEvent();})

    // check for reflows with a mutation observer
    var mo = new MutationObserver((mutations) => {
      setSizes();
      onScrollEvent();
      // console.log("reflow");
    });
    mo.observe(document.documentElement, {
      attributes: true,
      childList: true
    });

    // sledgehammer time
    setInterval( () => {setSizes()}, 1500 );


    onScrollEvent();

  }

  oS.env.activate = activateOnScreen
  oS.env.triggerEvent = onScrollEvent
  setTimeout(oS.env.activate, 222)

  return oS.env
}


module.exports = onScreen
// define(function (require) {
//     return onScreen;
// });
// export default onScreen

// export default function say (msg) {
//   console.log(msg);
// };
